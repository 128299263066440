<template>
  <div class="organizations-filters">
    <div class="row">
      <div class="col-50">
        <TextInput
            placeholder="Поиск"
            v-model="filters.q"
            class="invert"
            :required="true"
            classSpecial="autocomplete"
        />
      </div>
      <div class="col-50">
        <Select
            :array="cities.data"
            @select="filters.city = $event"
            :pre-selected="filters.city"
            placeholder="Город"/>
      </div>
    </div>
    <div class="row">
      <div class="col-50">
        <MultiSelect :array="inst_auditory"
                     placeholder="Возраст ребёнка"
                     @select="filters.child_age = $event"/>
      </div>
      <div class="col-50">
        <MultiSelect :array="types"
                     placeholder="Направление образования"
                     @select="filters.type = $event"/>
      </div>
    </div>
  </div>

</template>

<script>
import MultiSelect from '@/components/InputComponents/MultiSelect';

export default {
  name: 'OrganizationsFilters',

  components: {
    MultiSelect,
  },

  props: {
    filters: Object,
    preselectedTypes: Array,
  },

  computed: {
    cities: function() {
      return this.$cities;
    },
    types: function() {
      return this.$dictionaries.inst_type;
    },
    inst_auditory: function() {
      return this.$dictionaries.inst_auditory;
    },
  },
};
</script>
